// config
app.config(function(routes, $stateProvider, $locationProvider, $urlRouterProvider, $compileProvider){

    // for(var i = 0; i < routes.length; i++){
    //     $stateProvider.state(routes[i].name, routes[i].config);
    // }

    // $urlRouterProvider.otherwise('/');
    // $locationProvider.html5Mode(true);
    // $compileProvider.debugInfoEnabled(false);

});
