app.controller('home', function($scope, socket){

    $scope.open = function($event){

        if($event){
            $event.preventDefault();
        }

        $scope.isVisibleForm = true;

    };

    $scope.close = function(event){

        if(event){
            event.preventDefault();
        }

        $scope.request && $scope.request.$setPristine();
        $scope.request && $scope.request.$setUntouched();
        $scope.isVisibleForm = false;

    };

});
