// web socket service for interaction between web socket server
app.service('socket', function ($log, $rootScope, $window){

    var socket = io.connect($window.location.origin);

    this.on = function(event, cb, $scope){

        $scope = $scope || $rootScope;

        function handler(){
            if(cb){
                $scope.$apply(cb.apply(socket, arguments));
            }
        };

        socket.on(event, handler);
        $scope.$on('$destroy', socket.off.bind(socket, event, handler));

    };

    this.emit = function(event, body, cb, $scope){

        $scope = $scope || $rootScope;

        socket.emit(event, body, function(){

            if(cb){
                $scope.$apply(cb.apply(socket, arguments));
            }

        });

    };

    this.promise = function(event, body){

        return new Promise(function(resolve, reject){

            socket.emit(event, body, function(err, res){

                if(err){
                    reject(new Error(err));
                } else {
                    resolve(res);
                }

            });

        });

    };

});
