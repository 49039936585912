app.constant('routes', [
    {
        name: 'home',
        config: {
            templateUrl: '/partials/coming-soon.html',
            controller: 'home',
            url: '/'
        }
    }
]);
