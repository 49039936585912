app.directive('polygons', function(resize, storage){
    return{
        restrict: 'AE',
        priority: 100,
        controller: function($scope){

            var _this = this;

            // mesh properties
            var MESH = {
                width: 1.6,
                height: 1.6,
                depth: 10,
                segments: 16,
                slices: 8,
                xRange: 0.8,
                yRange: 0.8,
                ambient: '#555555',
                diffuse: '#a4cfff',
                speed: 0.002
            };

            // light properties
            var LIGHT = {
                count: 2,
                xyScalar: 1,
                zOffset: 100,
                ambient: '#303943', //272e34
                diffuse: '#090909', //050505
                speed: 15200,
                gravity: 1200,
                dampening: 0.95,
                minLimit: 10,
                maxLimit: null,
                minDistance: 20,
                maxDistance: 400,
                autopilot: true,
                draw: false,
                bounds: FSS.Vector3.create(),
                step: FSS.Vector3.create(
                    Math.randomInRange(0.2, 1.0),
                    Math.randomInRange(0.2, 1.0),
                    Math.randomInRange(0.2, 1.0)
                )
            };

            // render properties
            var WEBGL = 'webgl';
            var CANVAS = 'canvas';
            var SVG = 'svg';
            var RENDER = {
                renderer: CANVAS
            };

            // global properties
            var now, start = Date.now();
            var center = FSS.Vector3.create();
            var attractor = FSS.Vector3.create();
            var container
            var controls
            var output
            var renderer, scene, mesh, geometry, material;
            var webglRenderer, canvasRenderer, svgRenderer;
            var gui; // autopilotController;

            // methods
            this.initialise = function($element, $attributes){
                container = $element[0];
                controls = container.querySelector('#controls');
                output = $element[0];
                _this.createRenderer();
                _this.createScene();
                _this.createMesh();
                _this.createLights();

                if($attributes.polygonControls === 'true'){
                    _this.addControls();
                }

                _this.resize(container.offsetWidth, container.offsetHeight);
                _this.animate();
            };

            this.createRenderer = function(){
                webglRenderer = new FSS.WebGLRenderer();
                canvasRenderer = new FSS.CanvasRenderer();
                svgRenderer = new FSS.SVGRenderer();
                _this.setRenderer(RENDER.renderer);
            };

            this.setRenderer = function(index){
                if (renderer){
                    output.removeChild(renderer.element);
                }
                switch(index){
                    case WEBGL:
                        renderer = webglRenderer;
                        break;
                    case CANVAS:
                        renderer = canvasRenderer;
                        break;
                    case SVG:
                        renderer = svgRenderer;
                        break;
                }
                renderer.setSize(container.offsetWidth, container.offsetHeight);
                output.appendChild(renderer.element);
            };

            this.createScene = function(){
                scene = new FSS.Scene();
            };

            this.createMesh = function(){
                scene.remove(mesh);
                renderer.clear();
                geometry = new FSS.Plane(MESH.width * renderer.width, MESH.height * renderer.height, MESH.segments, MESH.slices);
                material = new FSS.Material(MESH.ambient, MESH.diffuse);
                mesh = new FSS.Mesh(geometry, material);
                scene.add(mesh);

                // augment vertices for animation
                var v, vertex;
                for (v = geometry.vertices.length - 1; v >= 0; v--){
                    vertex = geometry.vertices[v];
                    vertex.anchor = FSS.Vector3.clone(vertex.position);
                    vertex.step = FSS.Vector3.create(
                        Math.randomInRange(0.2, 1.0),
                        Math.randomInRange(0.2, 1.0),
                        Math.randomInRange(0.2, 1.0)
                    );
                    vertex.time = Math.randomInRange(0, Math.PIM2);
                }
            };

            this.createLights = function(){
                var l, light;
                for (l = scene.lights.length - 1; l >= 0; l--){
                    light = scene.lights[l];
                    scene.remove(light);
                }
                renderer.clear();
                for (l = 0; l < LIGHT.count; l++){
                    light = new FSS.Light(LIGHT.ambient, LIGHT.diffuse);
                    light.ambientHex = light.ambient.format();
                    light.diffuseHex = light.diffuse.format();
                    scene.add(light);

                    // augment light for animation
                    light.mass = Math.randomInRange(0.5, 1);
                    light.velocity = FSS.Vector3.create();
                    light.acceleration = FSS.Vector3.create();
                    light.force = FSS.Vector3.create();
                }
            };

            this.resize = function(width, height){
                renderer.setSize(width, height);
                FSS.Vector3.set(center, renderer.halfWidth, renderer.halfHeight);
                _this.createMesh();
            };

            this.animate = function(){
                now = Date.now() - start;
                _this.update();
                _this.render();
                requestAnimationFrame(_this.animate);
            };

            this.update = function(){
                var ox, oy, oz, l, light, v, vertex, offset = MESH.depth/2;

                // update bounds
                FSS.Vector3.copy(LIGHT.bounds, center);
                FSS.Vector3.multiplyScalar(LIGHT.bounds, LIGHT.xyScalar);

                // update attractor
                FSS.Vector3.setZ(attractor, LIGHT.zOffset);

                // overwrite the attractor position
                if (LIGHT.autopilot){
                    ox = Math.sin(LIGHT.step[0] * now * LIGHT.speed);
                    oy = Math.cos(LIGHT.step[1] * now * LIGHT.speed);
                    FSS.Vector3.set(attractor,
                    LIGHT.bounds[0]*ox,
                    LIGHT.bounds[1]*oy,
                    LIGHT.zOffset);
                }

                // animate Lights
                for (l = scene.lights.length - 1; l >= 0; l--){
                    light = scene.lights[l];

                    // reset the z position of the light
                    FSS.Vector3.setZ(light.position, LIGHT.zOffset);

                    // calculate the force Luke!
                    var D = Math.clamp(FSS.Vector3.distanceSquared(light.position, attractor), LIGHT.minDistance, LIGHT.maxDistance);
                    var F = LIGHT.gravity * light.mass / D;
                    FSS.Vector3.subtractVectors(light.force, attractor, light.position);
                    FSS.Vector3.normalise(light.force);
                    FSS.Vector3.multiplyScalar(light.force, F);

                    // cpdate the light position
                    FSS.Vector3.set(light.acceleration);
                    FSS.Vector3.add(light.acceleration, light.force);
                    FSS.Vector3.add(light.velocity, light.acceleration);
                    FSS.Vector3.multiplyScalar(light.velocity, LIGHT.dampening);
                    FSS.Vector3.limit(light.velocity, LIGHT.minLimit, LIGHT.maxLimit);
                    FSS.Vector3.add(light.position, light.velocity);
                }

                // animate vertices
                for (v = geometry.vertices.length - 1; v >= 0; v--){
                    vertex = geometry.vertices[v];
                    ox = Math.sin(vertex.time + vertex.step[0] * now * MESH.speed);
                    oy = Math.cos(vertex.time + vertex.step[1] * now * MESH.speed);
                    oz = Math.sin(vertex.time + vertex.step[2] * now * MESH.speed);
                    FSS.Vector3.set(vertex.position,
                    MESH.xRange*geometry.segmentWidth*ox,
                    MESH.yRange*geometry.sliceHeight*oy,
                    MESH.zRange*offset*oz - offset);
                    FSS.Vector3.add(vertex.position, vertex.anchor);
                }

                // set the geometry to dirty
                geometry.dirty = true;
            };

            this.render = function(){
                renderer.render(scene);

                // draw lights
                if (LIGHT.draw){
                    var l, lx, ly, light;
                    for (l = scene.lights.length - 1; l >= 0; l--){
                        light = scene.lights[l];
                        lx = light.position[0];
                        ly = light.position[1];
                        switch(RENDER.renderer){
                            case CANVAS:
                                renderer.context.lineWidth = 0.5;
                                renderer.context.beginPath();
                                renderer.context.arc(lx, ly, 10, 0, Math.PIM2);
                                renderer.context.strokeStyle = light.ambientHex;
                                renderer.context.stroke();
                                renderer.context.beginPath();
                                renderer.context.arc(lx, ly, 4, 0, Math.PIM2);
                                renderer.context.fillStyle = light.diffuseHex;
                                renderer.context.fill();
                                break;
                            case SVG:
                                lx += renderer.halfWidth;
                                ly = renderer.halfHeight - ly;
                                light.core.setAttributeNS(null, 'fill', light.diffuseHex);
                                light.core.setAttributeNS(null, 'cx', lx);
                                light.core.setAttributeNS(null, 'cy', ly);
                                renderer.element.appendChild(light.core);
                                light.ring.setAttributeNS(null, 'stroke', light.ambientHex);
                                light.ring.setAttributeNS(null, 'cx', lx);
                                light.ring.setAttributeNS(null, 'cy', ly);
                                renderer.element.appendChild(light.ring);
                                break;
                        }
                    }
                }
            };

            this.addControls = function(){
                var i, l, light, folder, controller;

                // create GUI
                gui = new dat.GUI({autoPlace:false});
                controls.appendChild(gui.domElement);

                // create folders
                renderFolder = gui.addFolder('Render');
                meshFolder = gui.addFolder('Mesh');
                lightFolder = gui.addFolder('Light');

                // open folders
                renderFolder.open();
                meshFolder.open();
                lightFolder.open();

                // add render controls
                controller = renderFolder.add(RENDER, 'renderer', {webgl:WEBGL, canvas:CANVAS, svg:SVG});

                controller.onChange(function(value){
                    _this.setRenderer(value);
                });

                // add mesh controls
                controller = meshFolder.addColor(MESH, 'ambient');

                controller.onChange(function(value){
                    for (i = 0, l = scene.meshes.length; i < l; i++){
                        scene.meshes[i].material.ambient.set(value);
                    }
                });

                controller = meshFolder.addColor(MESH, 'diffuse');

                controller.onChange(function(value){
                    for (i = 0, l = scene.meshes.length; i < l; i++){
                        scene.meshes[i].material.diffuse.set(value);
                    }
                });

                controller = meshFolder.add(MESH, 'width', 0.05, 2);

                controller.onChange(function(value){
                    if (geometry.width !== value * renderer.width){
                        _this.createMesh();
                    }
                });

                controller = meshFolder.add(MESH, 'height', 0.05, 2);

                controller.onChange(function(value){
                    if (geometry.height !== value * renderer.height){
                        _this.createMesh();
                    }
                });

                controller = meshFolder.add(MESH, 'depth', 0, 50);
                controller = meshFolder.add(MESH, 'segments', 1, 20);
                controller.step(1);

                controller.onChange(function(value){
                    if (geometry.segments !== value){
                        _this.createMesh();
                    }
                });

                controller = meshFolder.add(MESH, 'slices', 1, 20);
                controller.step(1);
                controller.onChange(function(value){
                if (geometry.slices !== value){
                    _this.createMesh();
                }
                });
                controller = meshFolder.add(MESH, 'xRange', 0, 1);
                controller = meshFolder.add(MESH, 'yRange', 0, 1);
                controller = meshFolder.add(MESH, 'speed', 0, 0.01);

                // add light controls
                autopilotController = lightFolder.add(LIGHT, 'autopilot');
                controller = lightFolder.addColor(LIGHT, 'ambient');

                controller.onChange(function(value){
                    for (i = 0, l = scene.lights.length; i < l; i++){
                        light = scene.lights[i];
                        light.ambient.set(value);
                        light.ambientHex = light.ambient.format();
                    }
                });

                controller = lightFolder.addColor(LIGHT, 'diffuse');

                controller.onChange(function(value){
                    for (i = 0, l = scene.lights.length; i < l; i++){
                        light = scene.lights[i];
                        light.diffuse.set(value);
                        light.diffuseHex = light.diffuse.format();
                    }
                });

                controller = lightFolder.add(LIGHT, 'count', 0, 5);
                controller.step(1);

                controller.onChange(function(value){
                    if (scene.lights.length !== value){
                        _this.createLights();
                    }
                });

                controller = lightFolder.add(LIGHT, 'zOffset', 0, 500);
                controller.step(1);
            };

            this.onWindowResize = function(event){
                _this.resize(container.offsetWidth, container.offsetHeight);
                _this.render();
            };
        },
        link:function($scope, $element, $attributes, controller){

            controller.initialise($element, $attributes);

            $scope.$on('resize', function(event, data){
                controller.onWindowResize();
            });

        }
    };
});
