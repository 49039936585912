// service for managing global events
app.service('events', function($rootScope){

    function is$scope(obj){
        return obj && obj.hasOwnProperty('$$ChildScope');
    };

    // event emiter - events broadcasted to entire app
    this.emit = function(){

        var $scope = null;
        var args = Array.prototype.slice.call(arguments);
        var last = args[args.length - 1];

        if(is$scope(last)){
            $scope = last;
            args = args.slice(0, -1);
        }

        $scope = $scope || $rootScope;
        $scope.$broadcast.apply($scope, args);

    };

    /*
    * event listener - listens on rootscope by default
    * can pass in optional scope to prevent memory leaks
    * ie multiple handlers on $rootScope
    *
    * $broadcasting from $rootScope doesn't matter, but listening does
    */
    this.on = function(event, cb, $scope){

        var $scope = $scope || $rootScope;

        $scope.$on(event, function(){

            var args = arguments;

            if(cb){
                cb.apply(null, args);
            }

        });

    };

});
