// animation service for common animations
app.service('animate', function(){

    function show($elem, opts){

        $elem = $elem[0] || $elem;

        opts = angular.merge({
            duration: .2,
            opacity: 1,
            display: 'block',
            ease: Circ.easeOut,
            overwrite: 'all'
        }, opts);

        TweenLite.to($elem, opts.duration, opts);

    };

    function hide($elem, opts){

        $elem = $elem[0] || $elem;

        opts = angular.merge({
            duration: .2,
            opacity: 0,
            display: 'none',
            ease: Circ.easeOut,
            overwrite: 'all'
        }, opts);

        TweenLite.to($elem, opts.duration, opts);

    };

    function slideInLeft($elem, opts){

        $elem = $elem[0] || $elem;

        opts = angular.merge({
            duration: .2,
            x: '0%',
            ease: Quad.easeOut,
            overwrite: 'all'
        }, opts);

        TweenLite.to($elem, opts.duration, opts);

    };

    function slideOutLeft($elem, opts){

        $elem = $elem[0] || $elem;

        opts = angular.merge({
            duration: .2,
            x: '-100%',
            ease: Quad.easeOut,
            overwrite: 'all'
        }, opts);

        TweenLite.to($elem, opts.duration, opts);

    };

    this.show = show;
    this.hide = hide;
    this.slideInLeft = slideInLeft;
    this.slideOutLeft = slideOutLeft;

});
