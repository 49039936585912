// web socket service for interaction between web socket server
app.service('helper', function($compile, $http, $templateCache, events){

    this.replaceTemplate = function($element, opts){

        var template = '/partials/' + opts.template + '.html';
        $element[0].removeAttribute(opts.name);

        $http.get(template, { cache: $templateCache }).success(function(response){
            $element.html(response);
            $compile($element)(opts.$scope);
            $element[0].setAttribute(opts.name, '');
        });

    };

});
