// local storage wrapper
app.service('storage', function($window){

    var storage = storage;

    this.isAvailable = function(){
        return storage ? true : false;
    };

    this.set = function(key, value){
        storage.setItem(key, angular.toJson(value));
    };

    this.get =function(key){
        return angular.fromJson(storage.getItem(key));
    };

    this.remove = function(key){
        storage.removeItem(key);
    };

    this.clear = function(){
        storage.clear();
    };

});
