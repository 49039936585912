app.directive('requestForm', function($http, $q, socket){
    return{
        restrict: 'A',
        templateUrl: '/partials/request-form.html',
        controller: function($scope){

            function FormData(){
                this.name = $scope.name;
                this.company = $scope.company;
                this.replyTo = $scope.email;
                this.budget = $scope.budget;
                this.message = $scope.message;
            };

            $scope.urlRegex = /(?!.*)/;

            $scope.handleSubmit = function(){

                if($scope.request.$valid){

                    $scope.isLoading = true;
                    $scope.isSubmitted = true;

                    socket.promise('form.contact', new FormData()).then(function(){

                        $scope.isSuccess = true;

                    }).catch(function(err){

                        $scope.isError = true;

                    }).finally(function(){

                        $scope.isLoading = false;
                        $scope.isComplete = true;
                        $scope.$apply();

                    });

                }

            };

        },
        link: function($scope, $elem, $attr, ctrl){

        }
    };
});
